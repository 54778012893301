import React, { useEffect, useState } from "react";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useSearchParams } from "react-router-dom";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useUser } from "$/state/user";
import {
  CoachEvent,
  GetSessionByIdQuery,
  OrderByArg,
  SessionState,
  useCoachAvailabilityQuery,
  useGetSessionByIdQuery,
  useListSessionsQuery,
} from "$/graphql/types.generated";
import { Intro } from "./components/Intro";
import { IsProgrammeCheck } from "./components/IsProgrammeCheck";
import { SchedulerProvider, useScheduler } from "../Scheduler/schedulerContext";
import { addMinutes, differenceInDays, parseISO, startOfHour } from "date-fns";
import { SchedulerTimes } from "../Scheduler/SchedulerTimes";
import { Scheduler } from "../Scheduler";
import { createFullAvailability } from "./helpers/createFullAvailability";
import { mergeAvailability } from "./helpers/mergeAvailability";
import { DateTime } from "luxon";
import { Confirm } from "./components/Confirm";
import { Mixpanel } from "$/tracking";

interface IProps extends IPageBaseProps {}

export type Send = ({ next, value }: { next: string; value: string | Array<string> }) => void;

interface OpenScheduleProps {
  coachId: string;
  context?: {
    package?: string;
  };
  lastDate?: string;
  isProgramme: boolean;
  lastSession: GetSessionByIdQuery;
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setSlot: React.Dispatch<React.SetStateAction<{ date: DateTime; time: number } | null>>;
}

export const OpenSchedule = ({
  coachId,
  lastDate,
  isProgramme,
  lastSession,
  setCurrentStep,
  setSlot,
}: OpenScheduleProps) => {
  const user = useUser();
  const { setEvents, month } = useScheduler();
  const [showTimeScheduler, setShowTimeScheduler] = useState(false);

  const { data, isFetching } = useCoachAvailabilityQuery(
    {
      coachId,
      startsAt: month.startOf("month").toISO(),
      endsAt:
        lastDate && differenceInDays(parseISO(lastDate), parseISO(month.endOf("month").toISO())) < 0
          ? lastDate
          : month.endOf("month").toISO(),
    },
    { enabled: !!coachId },
  );

  const { data: futureCoachSessions, isLoading } = useListSessionsQuery({
    where: {
      coachId: { equals: user.currentUser?.coach?.id || null },
      startsAt: { gte: addMinutes(startOfHour(new Date()), 30) },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const fullAvailabilty = createFullAvailability();

  useEffect(() => {
    if (!isFetching && futureCoachSessions?.listSessions) {
      const availabilityColours = mergeAvailability(
        fullAvailabilty,
        data.coachAvailability,
        futureCoachSessions.listSessions,
      );
      setEvents((availabilityColours ?? []) as Array<CoachEvent>);
    }
  }, [isFetching, isLoading]);

  return (
    <div className="w-full">
      {isFetching ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center h-[465px] md:h-[646px]">
          <h1>Loading availability</h1>
          <LoadingSpinner className="!h-12 !w-12" />
        </div>
      ) : (
        <>
          <div className="w-full flex flex-col text-center justify-center pb-4">
            <h2>
              You are booking {isProgramme ? "a programme session" : "an ad-hoc session"} with{" "}
              {lastSession.getSession?.teamMember?.user?.firstName}.
            </h2>
          </div>
          {showTimeScheduler ? (
            <SchedulerTimes
              hideTimeScheduler={() => setShowTimeScheduler(false)}
              selectTime={(date, time) => {
                Mixpanel.track("Coach Booking: Select Time", {
                  coach: user.currentUser?.coach?.id,
                  coachee: lastSession.getSession.teamMember.user.id,
                });
                setSlot({ date, time });
                setCurrentStep("confirm");
              }}
              openKey={true}
            />
          ) : (
            <Scheduler showTimeScheduler={() => setShowTimeScheduler(true)} open={true} />
          )}
        </>
      )}
    </div>
  );
};

export const BookNextSessionPage: React.FC<IProps> = () => {
  useScrollTo();
  const user = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState("intro");
  const [isProgramme, setIsProgramme] = useState<boolean | null>(null);
  const [slot, setSlot] = useState<{ date: DateTime; time: number } | null>(null);

  const sessionId = searchParams.get("sid");

  const { data: session, isFetching } = useGetSessionByIdQuery(
    { id: sessionId },
    { enabled: !!sessionId },
  );

  return (
    <div>
      {isFetching ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center h-[465px] md:h-[646px]">
          <h1>Loading session information</h1>
          <LoadingSpinner className="!h-12 !w-12" />
        </div>
      ) : !user.currentUser?.coach?.id ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center h-[465px] md:h-[646px]">
          <h1>You cannot access this page unless you are a coach.</h1>
        </div>
      ) : (
        <div className="my-10 w-screen">
          <div className="mx-auto">
            <HtmlMeta title="Book Next Session" />
            <div className="flex flex-col justify-between items-center w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
              <div className="rounded-lg w-full py-4">
                <div
                  className="flex flex-col items-center justify-center"
                  key={searchParams.get("sid")}
                >
                  <div className="w-screen flex justify-center">
                    {currentStep === "intro" && (
                      <Intro setCurrentStep={setCurrentStep} session={session} />
                    )}
                    {currentStep === "programme" && (
                      <IsProgrammeCheck
                        setCurrentStep={setCurrentStep}
                        setIsProgramme={setIsProgramme}
                        session={session}
                      />
                    )}
                    {currentStep === "selectTime" && (
                      <div className="md:w-[800px] max-w-[800px]">
                        <SchedulerProvider>
                          <OpenSchedule
                            coachId={user.currentUser.coach.id}
                            isProgramme={isProgramme}
                            lastSession={session}
                            setCurrentStep={setCurrentStep}
                            setSlot={setSlot}
                          />
                        </SchedulerProvider>
                      </div>
                    )}
                    {currentStep === "confirm" && (
                      <div className="md:w-[800px] max-w-[800px]">
                        <Confirm
                          setCurrentStep={setCurrentStep}
                          session={session}
                          futureSession={slot}
                          isProgramme={isProgramme}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
